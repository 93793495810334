import { Injectable } from '@angular/core';
import {
	ModuleQCMResponse,
	ModuleQCMResponseInterface,
	ModuleQCMResponsePayload,
} from './module-qcm-response';
import { ModuleQCMResponseSearchParamsInterface } from './module-qcm-response-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ModuleQCMResponseService extends BaseModelService<
	ModuleQCMResponse,
	ModuleQCMResponseInterface,
	ModuleQCMResponsePayload,
	ModuleQCMResponseSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'module-qcm-response';
	}
	/** @inheritDoc */
	protected new(object: ModuleQCMResponseInterface): ModuleQCMResponse {
		return new ModuleQCMResponse(object);
	}
}
