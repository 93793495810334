<nz-modal
	[(nzVisible)]="deleteOneModal"
	(nzOnCancel)="deleteOneModal = false"
	(nzOnOk)="removeOne()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
>
</nz-modal>
<nz-modal
	[(nzVisible)]="deleteManyModal"
	(nzOnCancel)="deleteManyModal = false"
	(nzOnOk)="removeMany()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-many' | translate"
	[nzOkLoading]="deleting"
>
</nz-modal>

<div class="plateau-list-container">
	<nz-table
		#table
		[nzData]="items"
		[nzSize]="'small'"
		[nzShowSizeChanger]="true"
		[nzFrontPagination]="false"
		[nzLoading]="refreshing"
		[nzLoadingDelay]="refreshingDelay"
		[nzTotal]="total"
		[nzNoResult]="notFound"
		[(nzPageIndex)]="pageIndex"
		[(nzPageSize)]="searchParams.props._limit"
		(nzPageIndexChange)="update()"
		(nzPageSizeChange)="update(true)"
	>
		<thead (nzSortOrderChange)="sort($event)">
			<tr>
				<th *ngIf="enableMultiSelect">
					<button
						(click)="deleteManyModal = true"
						nz-button
						[disabled]="selectedRows.length === 0"
						nzType="default"
						nzShape="circle"
					>
						<i nz-icon nzType="delete"></i>
					</button>
				</th>

				<th></th>
				<th>{{ 'plateau_title' | translate }}</th>

				<th>{{ 'plateau_created-at' | translate }}</th>
				<th *ngIf="showActions" class="actions-cell">
					{{ 'common_actions' | translate }}
				</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of table.data" (click)="onClick(row)">
				<td
					*ngIf="enableMultiSelect"
					nzShowCheckbox
					(nzCheckedChange)="selectRow($event, row)"
					[nzChecked]="isSelected(row)"
				></td>

				<td>
					<img
						src="{{ row.props.image_intro.getThumbnail() }}"
						alt=""
					/>
				</td>

				<td *ngIf="columns.includes('name')">
					{{ row.getTitle() }}
				</td>

				<td>
					{{ row.props.created_at | date: 'medium' }}
				</td>
				<td>
					<hpf-plateau-build-action
						[plateau]="row"
					></hpf-plateau-build-action>
				</td>
				<td *ngIf="showActions" class="actions-cell">
					<button
						(click)="
							rowToDelete = row;
							deleteOneModal = true;
							$event.stopPropagation()
						"
						nz-button
						nzType="default"
						nzShape="circle"
						[title]="'playlist-table_delete-button' | translate"
						class="ml-3"
					>
						<i nz-icon nzType="delete"></i>
					</button>
				</td>
			</tr>
		</tbody>
	</nz-table>
	<ng-template #notFound>
		<nz-empty
			[nzNotFoundContent]="'plateau_common_not-found' | translate"
		></nz-empty>
	</ng-template>
</div>
