import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import {
	ModuleQCMResponse,
	ModuleQCMResponseInterface,
} from '../module-qcm-response/module-qcm-response';
import { TextTranslated, TextTranslatedInterface } from '../text-translated';
import { Language } from '../language';
export interface ModuleQCMInterface extends BaseModelInterface {
	created_at: number | Date;
	title: (string | TextTranslated | TextTranslatedInterface)[];
	question: (string | TextTranslated | TextTranslatedInterface)[];
	image: string | Image | ImageInterface;
	responses: (string | ModuleQCMResponse | ModuleQCMResponseInterface)[];
}
export interface ModuleQCMPayload {
	title: (string | TextTranslated | TextTranslatedInterface)[];
	question: (string | TextTranslated | TextTranslatedInterface)[];
	image: string | Image | ImageInterface;
	responses: (string | ModuleQCMResponse | ModuleQCMResponseInterface)[];
}
type ModuleQCMPayloadKey = keyof ModuleQCMPayload;
export class ModuleQCM extends BaseModel<ModuleQCMInterface, ModuleQCMPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.getTitle();
	}

	getTitle(): string {
		const regex = /(<([^>]+)>)/gi;

		const lng = localStorage.getItem('lang');
		const tt = this.props.title.find(function (params: TextTranslated) {
			const l = params.props.lang as Language;
			return lng.toLowerCase() == l.props.name.toLowerCase();
		}) as TextTranslated;
		if (!tt) {
			return '';
		}
		return tt.props.text.replace(regex, '');
	}
	/** Denotes if the instance of image has been populated */
	imageExists(): boolean {
		return (
			!!this.props &&
			this.props.image instanceof Image &&
			this.props.image.exists()
		);
	}
	/** Denotes if the instance of responses has been populated */
	responsesExists(): boolean {
		return (
			!!this.props &&
			this.props.responses instanceof Array &&
			(<ModuleQCMResponse[]>this.props.responses).every((item) => {
				return item instanceof ModuleQCMResponse && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ModuleQCMInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.question instanceof Array) {
			this.props.question = (<TextTranslatedInterface[]>(
				this.props.question
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (typeof this.props.image === 'object') {
			this.props.image = new Image(<ImageInterface>this.props.image);
		}
		if (this.props.responses instanceof Array) {
			this.props.responses = (<ModuleQCMResponseInterface[]>(
				this.props.responses
			)).map((item) => {
				return typeof item === 'object'
					? new ModuleQCMResponse(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ModuleQCMInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.question instanceof Array) {
			props.question = (<TextTranslated[]>props.question).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (props.image instanceof Image) {
			props.image = props.image.toObject();
		}
		if (this.props.responses instanceof Array) {
			props.responses = (<ModuleQCMResponse[]>props.responses).map(
				(item) => {
					return item instanceof ModuleQCMResponse
						? item.toObject()
						: item;
				}
			);
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ModuleQCMPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ModuleQCMInterface);

		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: [];
		payload.question = payload.question
			? payload.question.map((i) => this.extractId(i))
			: [];
		payload.image = payload.image ? this.extractId(payload.image) : null;
		payload.responses = payload.responses
			? payload.responses.map((i) => this.extractId(i))
			: [];

		return payload as ModuleQCMPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ModuleQCMPayloadKey[] {
		return ['title', 'question', 'image', 'responses'];
	}
}
