import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import {
	BuildInfosService,
	BuildStatus,
} from '@app/services/build-infos.service';
import { BehaviorSubject, Subject, interval, merge, of, timer } from 'rxjs';
import { Plateau } from '../plateau';
import {
	distinctUntilChanged,
	filter,
	map,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { PlateauService } from '../plateau.service';
import { ErrorService } from '@app/services';
@Component({
	selector: 'hpf-plateau-build-action',
	templateUrl: './plateau-build-action.component.html',
})
export class PlateauBuildActionComponent implements OnInit, OnDestroy {
	private readonly unsubscribe: Subject<void> = new Subject<void>();

	@Output() statusChange = new Subject<BuildStatus | undefined>();

	@Input() plateau: Plateau;

	refreshStatus$ = new Subject<void>();

	status$ = new BehaviorSubject<BuildStatus | undefined>(undefined);

	buildInfos$ = this.status$.pipe(
		takeUntil(this.unsubscribe),
		tap(() => {
			this.moveToPublicModal = false;
			this.publishModal = false;
			this.modalProgress = false;
		}),
		switchMap((status) => {
			if (!status) return of(undefined);

			return Promise.resolve(
				this.buildInfosService.getLast(this.plateau.getId())
			);
		})
	);

	publishModal = false;
	moveToPublicModal = false;
	modalProgress = false;
	detailsModal = false;

	constructor(
		private readonly buildInfosService: BuildInfosService,
		private readonly plateauService: PlateauService,
		private errorService: ErrorService
	) {}

	ngOnInit() {
		this.refreshStatus$
			.pipe(
				takeUntil(this.unsubscribe),
				filter(() => this.plateau && !this.plateau.isNew()),
				switchMap(() =>
					Promise.resolve(
						this.buildInfosService
							.getStatus(this.plateau.getId())
							.then((status) => status || BuildStatus.created)
					)
				),
				distinctUntilChanged()
			)
			.subscribe((status) => {
				this.status$.next(status);
				this.statusChange.next(status);
			});

		// Init refresh status rate
		merge(timer(1000), interval(5000))
			.pipe(
				takeUntil(this.unsubscribe),
				map(() => undefined)
			)
			.subscribe(this.refreshStatus$);
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	async moveToPublic(): Promise<void> {
		this.modalProgress = true;
		try {
			await this.plateauService.moveToPublic(this.plateau);
			this.refreshStatus$.next();
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async createAssetBundle(): Promise<void> {
		this.modalProgress = true;
		try {
			await this.plateauService.createAssetBundle(this.plateau);
			this.refreshStatus$.next();
		} catch (error) {
			this.errorService.handle(error);
		}
	}
}
