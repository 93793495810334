import { Component, Input, OnInit } from '@angular/core';
import { ErrorService } from '@app/services';
import { ModuleQCMResponseSearchParams } from '../module-qcm-response-search-params';
import { ModuleQCMResponseService } from '../module-qcm-response.service';
@Component({
	selector: 'hpf-module-qcm-response-count',
	templateUrl: './module-qcm-response-count.component.html',
})
export class ModuleQCMResponseCountComponent implements OnInit {
	/** The filters */
	@Input()
	searchParams: ModuleQCMResponseSearchParams = new ModuleQCMResponseSearchParams();
	/** Count result */
	count: number;
	/** Load flag */
	loaded = false;
	/** Constructor */
	constructor(
		private moduleQCMResponseService: ModuleQCMResponseService,
		private errorService: ErrorService
	) {}
	/** Init */
	ngOnInit() {
		this.moduleQCMResponseService
			.count(this.searchParams.toObject())
			.then((count) => {
				this.count = count;
				this.loaded = true;
			})
			.catch((error) => this.errorService.handle(error));
	}
}
