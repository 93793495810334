import { Component } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { ModuleEnSavoirPlusSearchParams } from '../module-en-savoir-plus-search-params';

@Component({
	selector: 'hpf-module-en-savoir-plus-list-top-bar',
	templateUrl:
		'../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html',
})
export class ModuleEnSavoirPlusListTopBarComponent extends EntityListTopBarComponent<
	ModuleEnSavoirPlusSearchParams
> {}
