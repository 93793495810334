import { Component } from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import { ErrorService } from '@app/services';
import { ModuleEnSavoirPlusSearchParams } from '../module-en-savoir-plus-search-params';
import { ModuleEnSavoirPlusService } from '../module-en-savoir-plus.service';
import { ModuleEnSavoirPlus } from '../module-en-savoir-plus';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
	selector: 'hpf-module-en-savoir-plus-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class ModuleEnSavoirPlusSelectComponent extends EntitySelectComponent<
	ModuleEnSavoirPlus
> {
	protected modelName = 'module_en_savoir_plus';
	/**
	 * Constructor
	 * @param {ModuleEnSavoirPlusService} moduleEnSavoirPlusService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private moduleEnSavoirPlusService: ModuleEnSavoirPlusService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	ngOnInit() {
		super.ngOnInit();
		// Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if (!(model instanceof ModuleEnSavoirPlus)) return;
					if (this.multiple) {
						if (!this.model) this.model = [];
						(<ModuleEnSavoirPlus[]>this.model).push(
							<ModuleEnSavoirPlus>model
						);
					} else {
						this.model = <ModuleEnSavoirPlus>model;
					}
					this.refresh();
				}
			)
		);
	}
	/** @inheritDoc */
	protected async getList(): Promise<ModuleEnSavoirPlus[]> {
		const params = new ModuleEnSavoirPlusSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.moduleEnSavoirPlusService.list(params.toObject()))
			.items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<ModuleEnSavoirPlus> {
		return await this.moduleEnSavoirPlusService.get(id);
	}
}
