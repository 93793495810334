import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ErrorService } from '@app/services';
import { ModuleEnSavoirPlusSearchParams } from '../module-en-savoir-plus-search-params';
import { ModuleEnSavoirPlusService } from '../module-en-savoir-plus.service';
@Component({
	selector: 'hpf-module-en-savoir-plus-count',
	templateUrl: './module-en-savoir-plus-count.component.html',
})
export class ModuleEnSavoirPlusCountComponent implements OnInit {
	/** The filters */
	@Input()
	searchParams: ModuleEnSavoirPlusSearchParams = new ModuleEnSavoirPlusSearchParams();
	/** Count result */
	count: number;
	/** Load flag */
	loaded = false;
	/** Constructor */
	constructor(
		private moduleEnSavoirPlusService: ModuleEnSavoirPlusService,
		private errorService: ErrorService
	) {}
	/** Init */
	ngOnInit() {
		this.moduleEnSavoirPlusService
			.count(this.searchParams.toObject())
			.then((count) => {
				this.count = count;
				this.loaded = true;
			})
			.catch((error) => this.errorService.handle(error));
	}
}
