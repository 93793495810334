import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { ModuleQCM } from '../../module-qcm';

@Component({
	selector: 'hpf-module-qcm-create',
	templateUrl: './module-qcm-create.component.html',
})
export class ModuleQCMCreateComponent implements OnInit {
	/** The module qcm */
	moduleQCM = new ModuleQCM();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the module qcm is created */
	onCreate(moduleQCM: ModuleQCM): void {
		this.router
			.navigateByUrl(`/module-qcm/${moduleQCM.getId()}`)
			.catch((error) => this.errorService.handle(error));
	}
}
