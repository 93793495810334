import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'hpf-module-qcm-response',
	templateUrl: './module-qcm-response.component.html',
})
export class ModuleQCMResponseComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** Init */
	ngOnInit() {}
}
