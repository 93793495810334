<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form
	nz-form
	class="module-qcm-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="moduleQCM.isNew()">{{
									'module-qcm_create-form-title' | translate
								}}</span>
								<span *ngIf="!moduleQCM.isNew()">{{
									moduleQCM.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!moduleQCM.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate: { id: moduleQCM.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div>
							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'module_qcm',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'module_qcm',
									action: 'delete'
								}"
							>
								<button
									*ngIf="!moduleQCM.isNew() && deletable"
									class="ml-4"
									nz-button
									nzShape="circle"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12 col-md-6">
						<!-- Title -->
						<nz-form-item>
							<h4>{{ 'module-qcm_title' | translate }}</h4>
							<app-language-tab
								[name]="'title'"
								[model]="moduleQCM"
							></app-language-tab>
						</nz-form-item>
						<!-- /Title -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Question -->
						<nz-form-item>
							<h4>{{ 'module-qcm_question' | translate }}</h4>
							<app-language-tab
								[name]="'question'"
								[model]="moduleQCM"
							></app-language-tab>
						</nz-form-item>
						<!-- /Question -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Image -->
						<nz-form-item>
							<h4>{{ 'module-qcm_image' | translate }}</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('image')"
							>
								<hpf-image-select
									[controlName]="'image'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="moduleQCM.props.image"
									[filterEnabled]="true"
									[placeholder]="
										'image_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-image-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Image -->
					</div>
					<div class="col-12">
						<!-- Responses -->
						<h4>{{ 'module-qcm_responses' | translate }}</h4>

						<div
							*ngFor="
								let responseForm of form.get('responses')
									.controls;
								let i = index
							"
							class="my-5 position-relative"
						>
							<div class="subform-actions">
								<a
									nz-button
									nzType="link"
									(click)="removeDependency('responses', i)"
								>
									<i
										nz-icon
										nzType="close"
										nzTheme="outline"
									></i>
								</a>
							</div>
							<div class="subform-id">
								<span>
									{{ i + 1 }}
								</span>
							</div>
							<div class="ml-5">
								<hpf-module-qcm-response-inline-form
									#responsesFormElm
									[(moduleQCMResponse)]="
										moduleQCM.props.responses[i]
									"
									[form]="responseForm"
								></hpf-module-qcm-response-inline-form>
							</div>
						</div>
						<button
							nz-button
							nzType="default"
							type="button"
							nzBlock
							nzSize="large"
							(click)="
								addDependency('responses');
								$event.stopPropagation()
							"
						>
							<i nz-icon nzType="plus" nzTheme="outline"></i
							>{{
								'module-qcm_responses_dependencies_add-btn'
									| translate
							}}
						</button>
						<!-- /Responses -->
					</div>
				</div>

				<nz-divider
					*ngIf="!moduleQCM.isNew() && !readOnly"
				></nz-divider>
				<div *ngIf="!moduleQCM.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>{{ 'module-qcm_-id' | translate }}</h4>
							<div>{{ moduleQCM.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>{{ 'module-qcm_created-at' | translate }}</h4>
							<div>
								{{
									moduleQCM.props.created_at | date: 'medium'
								}}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Title -->
						<div class="mb-4">
							<h4>{{ 'module-qcm_title' | translate }}</h4>
							<div>{{ moduleQCM.props.title }}</div>
						</div>
						<!-- /Title -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Question -->
						<div class="mb-4">
							<h4>{{ 'module-qcm_question' | translate }}</h4>
							<div>{{ moduleQCM.props.question }}</div>
						</div>
						<!-- /Question -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Image -->
						<div class="mb-4">
							<h4>{{ 'module-qcm_image' | translate }}</h4>
							<div *ngIf="moduleQCM.imageExists()">
								<a
									[routerLink]="[
										'/image',
										moduleQCM.props.image.getId()
									]"
								>
									{{ moduleQCM.props.image.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Image -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Responses -->
						<div class="mb-4">
							<h4>{{ 'module-qcm_responses' | translate }}</h4>
							<div *ngIf="moduleQCM.responsesExists()">
								<div
									*ngFor="
										let item of moduleQCM.props.responses
									"
								>
									<a
										class="mr-2"
										[routerLink]="[
											'/module-qcm-response',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Responses -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
