import { Component } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { ModuleQCMResponseSearchParams } from '../module-qcm-response-search-params';

@Component({
	selector: 'hpf-module-qcm-response-list-top-bar',
	templateUrl:
		'../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html',
})
export class ModuleQCMResponseListTopBarComponent extends EntityListTopBarComponent<
	ModuleQCMResponseSearchParams
> {}
