import { Component, Input } from '@angular/core';
import { ModuleQCMResponseFormComponent } from '../module-qcm-response-form/module-qcm-response-form.component';
import { FormGroup } from '@angular/forms';
import { ModuleQCMResponse } from '../module-qcm-response';
@Component({
	selector: 'hpf-module-qcm-response-inline-form',
	templateUrl: './module-qcm-response-inline-form.component.html',
})
export class ModuleQCMResponseInlineFormComponent extends ModuleQCMResponseFormComponent {
	@Input() form: FormGroup;

	static getForm(moduleQCMResponse?: ModuleQCMResponse): FormGroup {
		return ModuleQCMResponseFormComponent.getForm(moduleQCMResponse);
	}
}
