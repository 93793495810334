import { computeMsgId } from '@angular/compiler';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	ContentChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
	FormArray,
} from '@angular/forms';
import { Language } from '../language';
import { LanguageSearchParams } from '../language-search-params';
import { LanguageService } from '../language.service';
import { TextTranslated } from '../../text-translated/text-translated';
import { TextTranslatedService } from '../../text-translated/text-translated.service';
import { Text } from '@angular/compiler/src/render3/r3_ast';
export class TabComponent {
	active = false;
	language: Language;
	texttranslated: TextTranslated;
	formControl: FormControl;
	constructor(lng: Language) {
		this.language = lng;
	}
}

@Component({
	selector: 'app-language-tab',
	templateUrl: './language-tab.component.html',
	styleUrls: ['./language-tab.component.css'],
})
export class LanguageTabComponent implements OnInit {
	@Input() name: string;
	@Input() model;
	items: TabComponent[] = [];

	/** The form group to use */
	forms: FormGroup;

	ngOnInit() {
		this.getList().then((results) => {
			results.forEach((element) => {
				this.items.push(new TabComponent(element));
			});
			this.after();
		});
	}

	/**
	 * submit
	 */
	public async submit() {
		this.updateModel();
		this.model.props[this.name] = await Promise.all(
			(<TextTranslated[]>this.model.props[this.name]).map(
				async (poiText): Promise<TextTranslated> => {
					const payload = poiText.toPayload();
					if (payload.text == null) {
						payload.text = '';
					}
					payload.name = poiText.props.lang + this.name;

					if (poiText.isNew()) {
						const create = await this.textService.create(payload);
						poiText.props._id = create.getId();
					} else {
						await this.textService.update(poiText.getId(), payload);
					}
					return poiText;
				}
			)
		);
	}

	updateModel() {
		this.items.forEach((element) => {
			element.texttranslated.props.text = element.formControl.value;
		});
	}

	after() {
		var formControls = {};
		// get all active tabs
		let activeTabs = this.items.filter((tab) => tab.active);
		if (!this.model.props[this.name]) this.model.props[this.name] = [];

		var tts = this.model.props[this.name] as TextTranslated[];
		this.items.forEach((element) => {
			var tt = tts.find((t) => {
				const langId =
					typeof t.props.lang !== 'string'
						? (<Language>t.props.lang).props._id
						: t.props.lang;
				return langId === element.language.props._id;
			});
			if (!tt) {
				tt = new TextTranslated();
				tt.props.lang = element.language.getId();
				this.model.props[this.name].push(tt);
			}
			element.texttranslated = tt;

			element.formControl = new FormControl(tt.props.text);
			formControls[element.language.getLabel()] = element.formControl;
		});

		this.forms = this.formBuilder.group(formControls);
		// if there is no active tab set, activate the first
		if (activeTabs.length === 0) {
			this.selectTab(this.items[0]);
		}
	}

	selectTab(tab: TabComponent) {
		// deactivate all tabs
		this.items.forEach((tab) => (tab.active = false));

		// activate the tab the user has clicked on.
		tab.active = true;
	}

	protected async getList(): Promise<Language[]> {
		const params = new LanguageSearchParams({
			_limit: 100,
			_page: 0,
			_sort: 'created_at',
			_order: 'asc',
		});

		return (await this.languageService.list(params.toObject())).items;
	}

	constructor(
		private languageService: LanguageService,
		private textService: TextTranslatedService,
		private formBuilder: FormBuilder
	) {}
}
