import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { ModuleQCMResponse } from '../../module-qcm-response';
import { ModuleQCMResponseService } from '../../module-qcm-response.service';
@Component({
	selector: 'hpf-module-qcm-response-edit',
	templateUrl: './module-qcm-response-edit.component.html',
})
export class ModuleQCMResponseEditComponent implements OnInit, OnDestroy {
	/** The module qcm response */
	moduleQCMResponse = new ModuleQCMResponse();
	/** The module qcm response */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private moduleQCMResponseService: ModuleQCMResponseService
	) {}
	/** Init */
	ngOnInit() {
		// Get the module qcm response after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch module qcm response from API */
	private refresh() {
		this.moduleQCMResponseService
			.get(this.id, { _populate: ['*'] })
			.then((moduleQCMResponse: ModuleQCMResponse) => {
				this.moduleQCMResponse.fromObject(moduleQCMResponse.toObject());
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the module qcm response is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the module qcm response is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/module-qcm-response')
			.catch((error) => this.errorService.handle(error));
	}
}
