<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
>
</nz-modal>
<form
	nz-form
	class="point-of-interest-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="pointOfInterest.isNew()">{{
									'point-of-interest_create-form-title'
										| translate
								}}</span>
								<span *ngIf="!pointOfInterest.isNew()">{{
									pointOfInterest.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!pointOfInterest.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate
											: { id: pointOfInterest.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div>
							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'point_of_interest',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'point_of_interest',
									action: 'delete'
								}"
							>
								<button
									*ngIf="
										!pointOfInterest.isNew() && deletable
									"
									class="ml-4"
									nz-button
									nzShape="circle"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12 col-md-6">
						<!-- Name -->

						<nz-form-item>
							<h4>{{ 'point-of-interest_name' | translate }}</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Name -->
					</div>

					<div class="col-12 col-md-6">
						<!-- Published -->

						<nz-form-item>
							<h4>
								{{ 'point-of-interest_published' | translate }}
							</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="published"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Published -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Thumbnail -->

						<nz-form-item>
							<h4>
								{{ 'point-of-interest_thumbnail' | translate }}
							</h4>
							<app-image-modal-button
								formControlName="thumbnail"
							></app-image-modal-button>
						</nz-form-item>
						<!-- /Thumbnail -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Plateau -->

						<nz-form-item>
							<h4>
								{{ 'point-of-interest_plateau' | translate }}
							</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('plateau')"
							>
								<hpf-plateau-select
									[controlName]="'plateau'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="pointOfInterest.props.plateau"
									[filterEnabled]="true"
									[placeholder]="
										'plateau_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-plateau-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Plateau -->
					</div>

					<div class="col-12 col-md-6">
						<!-- QCM List -->

						<nz-form-item>
							<h4>
								{{ 'point-of-interest_qcm-list' | translate }}
							</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('qcm_list')"
							>
								<hpf-module-qcm-select
									[controlName]="'qcm_list'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="pointOfInterest.props.qcm_list"
									[multiple]="true"
									[nullable]="true"
									[filterEnabled]="true"
									[placeholder]="
										'plateau_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-module-qcm-select>
								<div
									class="mt-2 small"
									*ngIf="
										form.get('qcm_list').value &&
										form.get('qcm_list').value.length > 0
									"
								>
									<i
										nz-icon
										nzType="link"
										nzTheme="outline"
									></i>
									<span
										class="pipe-list"
										*ngFor="
											let item of form.get('qcm_list')
												.value
										"
									>
										<a
											[routerLink]="[
												'/module-qcm',
												item.getId()
											]"
										>
											{{ item.getLabel() }}
										</a>
									</span>
								</div>
							</nz-form-control>
						</nz-form-item>
						<!-- /QCM List -->
					</div>

					<div class="col-12 col-md-6">
						<!-- Modules en savoir plus -->
						<nz-form-item>
							<h4>
								{{
									'point-of-interest_modules-en-savoir-plus'
										| translate
								}}
							</h4>
							<nz-form-control
								[nzValidateStatus]="
									form.get('modules_en_savoir_plus')
								"
							>
								<hpf-module-en-savoir-plus-select
									[controlName]="'modules_en_savoir_plus'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="
										pointOfInterest.props
											.modules_en_savoir_plus
									"
									[multiple]="true"
									[nullable]="true"
									[filterEnabled]="true"
									[placeholder]="
										'module-en-savoir-plus_common_select-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-module-en-savoir-plus-select>

								<div
									class="mt-2 small"
									*ngIf="
										form.get('modules_en_savoir_plus')
											.value &&
										form.get('modules_en_savoir_plus').value
											.length > 0
									"
								>
									<i
										nz-icon
										nzType="link"
										nzTheme="outline"
									></i>
									<span
										class="pipe-list"
										*ngFor="
											let item of form.get(
												'modules_en_savoir_plus'
											).value
										"
									>
										<a
											[routerLink]="[
												'/module-en-savoir-plus',
												item.getId()
											]"
										>
											{{ item.getLabel() }}
										</a>
									</span>
								</div>
							</nz-form-control>
						</nz-form-item>
						<!-- /Modules en savoir plus -->
					</div>

					<!-- Jumbotron -->
					<div class="jumbotron col-12">
						<div class="row">
							<div class="col-md-6">
								<!-- Top Image -->

								<nz-form-item>
									<h4>
										{{
											'point-of-interest_top-image'
												| translate
										}}
									</h4>
									<app-image-modal-button
										formControlName="top_image"
									></app-image-modal-button>
								</nz-form-item>
								<!-- /Top Image -->
							</div>
							<div class="col-md-6">
								<!-- Diaporama -->

								<nz-form-item>
									<h4>
										{{
											'point-of-interest_diaporama'
												| translate
										}}
									</h4>
									<nz-form-control
										[nzValidateStatus]="
											form.get('diaporama')
										"
									>
										<hpf-diaporama-select
											[controlName]="'diaporama'"
											[formGroup]="form"
											[quickFormEnabled]="
												quickFormEnabled
											"
											[(model)]="
												pointOfInterest.props.diaporama
											"
											[nullable]="true"
											[filterEnabled]="true"
											[placeholder]="
												'diaporama_common_search-placeholder'
													| translate
											"
											[emptyLabel]="
												'common_empty' | translate
											"
										>
										</hpf-diaporama-select>
									</nz-form-control>
								</nz-form-item>
								<!-- /Diaporama -->
							</div>
						</div>

						<div>
							<!-- Title -->

							<h4>{{ 'point-of-interest_title' | translate }}</h4>
							<app-language-tab
								[name]="'title'"
								[model]="pointOfInterest"
							></app-language-tab>
						</div>

						<div>
							<!-- Categories -->

							<nz-form-item>
								<h4>
									{{
										'point-of-interest_categories'
											| translate
									}}
								</h4>
								<nz-form-control
									[nzValidateStatus]="form.get('categories')"
								>
									<hpf-category-select
										[controlName]="'categories'"
										[formGroup]="form"
										[quickFormEnabled]="quickFormEnabled"
										[(model)]="
											pointOfInterest.props.categories
										"
										[nullable]="true"
										[filterEnabled]="true"
										[placeholder]="
											'category_common_search-placeholder'
												| translate
										"
										[emptyLabel]="
											'common_empty' | translate
										"
									>
									</hpf-category-select>
								</nz-form-control>
							</nz-form-item>
							<!-- /Categories -->
						</div>
						<div>
							<!-- Description -->

							<h4>
								{{
									'point-of-interest_description' | translate
								}}
							</h4>
							<app-language-tab
								[name]="'description'"
								[model]="pointOfInterest"
							></app-language-tab>
						</div>
						<div>
							<!-- Url -->

							<nz-form-item>
								<h4>
									{{ 'point-of-interest_url' | translate }}
								</h4>
								<nz-form-control>
									<input
										nz-input
										class="w-100"
										type="text"
										[placeholder]="
											'common_value-string' | translate
										"
										formControlName="url"
									/>
								</nz-form-control>
							</nz-form-item>
							<!-- /Url -->
						</div>
					</div>
				</div>

				<nz-divider
					*ngIf="!pointOfInterest.isNew() && !readOnly"
				></nz-divider>
				<div *ngIf="!pointOfInterest.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>{{ 'point-of-interest_-id' | translate }}</h4>
							<div>{{ pointOfInterest.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_created-at' | translate }}
							</h4>
							<div>
								{{
									pointOfInterest.props.created_at
										| date: 'medium'
								}}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Owner -->
						<div class="mb-4">
							<h4>{{ 'point-of-interest_owner' | translate }}</h4>
							<div *ngIf="pointOfInterest.ownerExists()">
								<a
									[routerLink]="[
										'/user',
										pointOfInterest.props.owner.getId()
									]"
								>
									{{ pointOfInterest.props.owner.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Owner -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Name -->
						<div class="mb-4">
							<h4>{{ 'point-of-interest_name' | translate }}</h4>
							<div>{{ pointOfInterest.props.name }}</div>
						</div>
						<!-- /Name -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Plateau -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_plateau' | translate }}
							</h4>
							<div *ngIf="pointOfInterest.plateauExists()">
								<a
									[routerLink]="[
										'/plateau',
										pointOfInterest.props.plateau.getId()
									]"
								>
									{{
										pointOfInterest.props.plateau.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Plateau -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Categories -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_categories' | translate }}
							</h4>
							<div *ngIf="pointOfInterest.categoriesExists()">
								<a
									[routerLink]="[
										'/category',
										pointOfInterest.props.categories.getId()
									]"
								>
									{{
										pointOfInterest.props.categories.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Categories -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Title -->
						<div class="mb-4">
							<h4>{{ 'point-of-interest_title' | translate }}</h4>
							<div *ngIf="pointOfInterest.titleExists()">
								<div
									*ngFor="
										let item of pointOfInterest.props.title
									"
								>
									<a
										class="mr-2"
										[routerLink]="[
											'/text-translated',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Title -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Description -->
						<div class="mb-4">
							<h4>
								{{
									'point-of-interest_description' | translate
								}}
							</h4>
							<div *ngIf="pointOfInterest.descriptionExists()">
								<div
									*ngFor="
										let item of pointOfInterest.props
											.description
									"
								>
									<a
										class="mr-2"
										[routerLink]="[
											'/text-translated',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Description -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Url -->
						<div class="mb-4">
							<h4>{{ 'point-of-interest_url' | translate }}</h4>
							<div>{{ pointOfInterest.props.url }}</div>
						</div>
						<!-- /Url -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Diaporama -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_diaporama' | translate }}
							</h4>
							<div *ngIf="pointOfInterest.diaporamaExists()">
								<a
									[routerLink]="[
										'/diaporama',
										pointOfInterest.props.diaporama.getId()
									]"
								>
									{{
										pointOfInterest.props.diaporama.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Diaporama -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Top Image -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_top-image' | translate }}
							</h4>
							<div *ngIf="pointOfInterest.topImageExists()">
								<a
									[routerLink]="[
										'/image',
										pointOfInterest.props.top_image.getId()
									]"
								>
									{{
										pointOfInterest.props.top_image.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Top Image -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Thumbnail -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_thumbnail' | translate }}
							</h4>
							<div *ngIf="pointOfInterest.thumbnailExists()">
								<a
									[routerLink]="[
										'/image',
										pointOfInterest.props.thumbnail.getId()
									]"
								>
									{{
										pointOfInterest.props.thumbnail.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Thumbnail -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Published -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_published' | translate }}
							</h4>
							<div>
								<span
									*ngIf="
										pointOfInterest.props.published ===
											true ||
										pointOfInterest.props.published ===
											false
									"
								>
									{{
										(pointOfInterest.props.published
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Published -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Deleted -->
						<div class="mb-4">
							<h4>
								{{ 'point-of-interest_deleted' | translate }}
							</h4>
							<div>
								<span
									*ngIf="
										pointOfInterest.props.deleted ===
											true ||
										pointOfInterest.props.deleted === false
									"
								>
									{{
										(pointOfInterest.props.deleted
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Deleted -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
