import { Component } from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import { ErrorService } from '@app/services';
import { ModuleQCMSearchParams } from '../module-qcm-search-params';
import { ModuleQCMService } from '../module-qcm.service';
import { ModuleQCM } from '../module-qcm';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
	selector: 'hpf-module-qcm-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class ModuleQCMSelectComponent extends EntitySelectComponent<ModuleQCM> {
	protected modelName = 'module_qcm';
	/**
	 * Constructor
	 * @param {ModuleQCMService} moduleQCMService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private moduleQCMService: ModuleQCMService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	ngOnInit() {
		super.ngOnInit();
		// Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if (!(model instanceof ModuleQCM)) return;
					if (this.multiple) {
						if (!this.model) this.model = [];
						(<ModuleQCM[]>this.model).push(<ModuleQCM>model);
					} else {
						this.model = <ModuleQCM>model;
					}
					this.refresh();
				}
			)
		);
	}
	/** @inheritDoc */
	protected async getList(): Promise<ModuleQCM[]> {
		const params = new ModuleQCMSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
			_populate: ['title.lang'],
		});

		return (await this.moduleQCMService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<ModuleQCM> {
		return await this.moduleQCMService.get(id);
	}
}
