import { Component, Input, OnInit } from '@angular/core';
import { ModuleQCMSearchParams } from '../module-qcm-search-params';
@Component({
	selector: 'hpf-module-qcm-filters',
	templateUrl: './module-qcm-filters.component.html',
})
export class ModuleQCMFiltersComponent implements OnInit {
	/** The filters */
	@Input() searchParams: ModuleQCMSearchParams = new ModuleQCMSearchParams();
	/** Denotes if the page number should be set to 0 when filters change */
	@Input() resetPage = false;
	/** Constructor */
	constructor() {}
	/** Init */
	ngOnInit() {}
	/** Called when an input is changed */
	onChange() {
		if (this.resetPage) {
			this.searchParams.props._page = 0;
		}
		this.searchParams.next();
	}
	/** Clear all filters */
	public resetSearchParams() {
		this.searchParams.fromObject({});
	}
}
