<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form
	nz-form
	class="module-en-savoir-plus-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="moduleEnSavoirPlus.isNew()">{{
									'module-en-savoir-plus_create-form-title'
										| translate
								}}</span>
								<span *ngIf="!moduleEnSavoirPlus.isNew()">{{
									moduleEnSavoirPlus.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!moduleEnSavoirPlus.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate
											: { id: moduleEnSavoirPlus.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div>
							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'module_en_savoir_plus',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'module_en_savoir_plus',
									action: 'delete'
								}"
							>
								<button
									*ngIf="
										!moduleEnSavoirPlus.isNew() && deletable
									"
									class="ml-4"
									nz-button
									nzShape="circle"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12">
						<!-- Label -->
						<nz-form-item>
							<h4>
								{{ 'module-en-savoir-plus_label' | translate }}
							</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="label"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Label -->
					</div>

					<div class="col-12">
						<!-- Title -->
						<nz-form-item>
							<h4>
								{{ 'module-en-savoir-plus_title' | translate }}
							</h4>
							<app-language-tab
								[name]="'title'"
								[model]="moduleEnSavoirPlus"
							></app-language-tab>
						</nz-form-item>
						<!-- /Title -->
					</div>

					<div class="col-12">
						<!-- Subtitle -->
						<nz-form-item>
							<h4>
								{{
									'module-en-savoir-plus_subtitle' | translate
								}}
							</h4>
							<app-language-tab
								[name]="'subtitle'"
								[model]="moduleEnSavoirPlus"
							></app-language-tab>
						</nz-form-item>
						<!-- /Subtitle -->
					</div>
					<div class="col-12">
						<!-- Description -->
						<nz-form-item>
							<h4>
								{{
									'module-en-savoir-plus_description'
										| translate
								}}
							</h4>
							<app-language-tab
								[name]="'description'"
								[model]="moduleEnSavoirPlus"
							></app-language-tab>
						</nz-form-item>
						<!-- /Description -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Url -->
						<nz-form-item>
							<h4>
								{{ 'module-en-savoir-plus_url' | translate }}
							</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="url"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Url -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Image -->
						<nz-form-item>
							<h4>
								{{ 'module-en-savoir-plus_image' | translate }}
							</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('image')"
							>
								<hpf-image-select
									[controlName]="'image'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="moduleEnSavoirPlus.props.image"
									[filterEnabled]="true"
									[placeholder]="
										'image_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-image-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Image -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Diaporama -->
						<nz-form-item>
							<h4>
								{{
									'module-en-savoir-plus_diaporama'
										| translate
								}}
							</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('diaporama')"
							>
								<hpf-diaporama-select
									[controlName]="'diaporama'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="
										moduleEnSavoirPlus.props.diaporama
									"
									[nullable]="true"
									[filterEnabled]="true"
									[placeholder]="
										'diaporama_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-diaporama-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Diaporama -->
					</div>
				</div>

				<nz-divider
					*ngIf="!moduleEnSavoirPlus.isNew() && !readOnly"
				></nz-divider>
				<div *ngIf="!moduleEnSavoirPlus.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>
								{{ 'module-en-savoir-plus_-id' | translate }}
							</h4>
							<div>{{ moduleEnSavoirPlus.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>
								{{
									'module-en-savoir-plus_created-at'
										| translate
								}}
							</h4>
							<div>
								{{
									moduleEnSavoirPlus.props.created_at
										| date: 'medium'
								}}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Title -->
						<div class="mb-4">
							<h4>
								{{ 'module-en-savoir-plus_title' | translate }}
							</h4>
							<div>{{ moduleEnSavoirPlus.props.title }}</div>
						</div>
						<!-- /Title -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Label -->
						<div class="mb-4">
							<h4>
								{{ 'module-en-savoir-plus_label' | translate }}
							</h4>
							<div>{{ moduleEnSavoirPlus.props.label }}</div>
						</div>
						<!-- /Label -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Subtitle -->
						<div class="mb-4">
							<h4>
								{{
									'module-en-savoir-plus_subtitle' | translate
								}}
							</h4>
							<div>{{ moduleEnSavoirPlus.props.subtitle }}</div>
						</div>
						<!-- /Subtitle -->
					</div>
					<div class="col-12" *ngIf="readOnly">
						<!-- Description -->
						<div class="mb-4">
							<h4>
								{{
									'module-en-savoir-plus_description'
										| translate
								}}
							</h4>
							<div
								class="bg-light pt-3 pb-1 px-3 rounded"
								[innerHTML]="
									moduleEnSavoirPlus.props.description
								"
							></div>
						</div>
						<!-- /Description -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Url -->
						<div class="mb-4">
							<h4>
								{{ 'module-en-savoir-plus_url' | translate }}
							</h4>
							<div>{{ moduleEnSavoirPlus.props.url }}</div>
						</div>
						<!-- /Url -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Image -->
						<div class="mb-4">
							<h4>
								{{ 'module-en-savoir-plus_image' | translate }}
							</h4>
							<div *ngIf="moduleEnSavoirPlus.imageExists()">
								<a
									[routerLink]="[
										'/image',
										moduleEnSavoirPlus.props.image.getId()
									]"
								>
									{{
										moduleEnSavoirPlus.props.image.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Image -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Diaporama -->
						<div class="mb-4">
							<h4>
								{{
									'module-en-savoir-plus_diaporama'
										| translate
								}}
							</h4>
							<div *ngIf="moduleEnSavoirPlus.diaporamaExists()">
								<a
									[routerLink]="[
										'/image',
										moduleEnSavoirPlus.props.diaporama.getId()
									]"
								>
									{{
										moduleEnSavoirPlus.props.diaporama.getLabel()
									}}
								</a>
							</div>
						</div>
						<!-- /Diaporama -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
