import { Injectable } from '@angular/core';
import {
	ModuleEnSavoirPlus,
	ModuleEnSavoirPlusInterface,
	ModuleEnSavoirPlusPayload,
} from './module-en-savoir-plus';
import { ModuleEnSavoirPlusSearchParamsInterface } from './module-en-savoir-plus-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ModuleEnSavoirPlusService extends BaseModelService<
	ModuleEnSavoirPlus,
	ModuleEnSavoirPlusInterface,
	ModuleEnSavoirPlusPayload,
	ModuleEnSavoirPlusSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'module-en-savoir-plus';
	}
	/** @inheritDoc */
	protected new(object: ModuleEnSavoirPlusInterface): ModuleEnSavoirPlus {
		return new ModuleEnSavoirPlus(object);
	}
}
