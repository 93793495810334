import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

export enum BuildStatus {
	inProgress = 'in-progress',
	private = 'private',
	created = 'created',
}

export interface BuildInfoInterface {
	_id: string;
	plateauId: string;
	created_at: Date;
	status: BuildStatus;
}

export interface BuildInfoPayloadInterface
	extends Omit<BuildInfoInterface, 'created_at'> {
	created_at: string;
}

@Injectable()
export class BuildInfosService {
	/** Base route for this module */
	private _uri = `${environment.api.uri}/admin/assetbundle/build/info`;

	/** Constructor */
	constructor(private http: HttpClient) {}

	async getStatus(plateauId: string): Promise<BuildStatus | undefined> {
		const buildInfo = await this.getLast(plateauId).catch(() => undefined);
		return buildInfo?.status;
	}

	/** Confirm the email address */
	async getLast(plateauId: string): Promise<BuildInfoInterface> {
		return this.http
			.get<BuildInfoPayloadInterface>(`${this._uri}/${plateauId}/last`, {
				withCredentials: true,
			})
			.toPromise()
			.then((payload) => this.payloadToBuildInfo(payload));
	}

	private payloadToBuildInfo(
		payload: BuildInfoPayloadInterface
	): BuildInfoInterface {
		return {
			...payload,
			created_at: new Date(payload.created_at),
		};
	}
}
