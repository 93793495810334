import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { PointOfInterest } from '../../point-of-interest';
import { PointOfInterestService } from '../../point-of-interest.service';
import { ActivityLogSearchParams } from '@app/plugins/activity';
@Component({
	selector: 'hpf-point-of-interest-edit',
	templateUrl: './point-of-interest-edit.component.html',
})
export class PointOfInterestEditComponent implements OnInit, OnDestroy {
	/** The point of interest */
	pointOfInterest = new PointOfInterest();
	/**	History search params */
	historySearchParams: ActivityLogSearchParams;
	/** The point of interest */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private pointOfInterestService: PointOfInterestService
	) {}
	/** Init */
	ngOnInit() {
		// Get the point of interest after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch point of interest from API */
	private refresh() {
		this.pointOfInterestService
			.get(this.id, {
				_populate: [
					'categories',
					'description',
					'diaporama',
					'modules_en_savoir_plus.title.lang',
					'owner',
					'plateau',
					'qcm_list.title.lang',
					'thumbnail',
					'title',
					'top_image',
				],
			})
			.then((pointOfInterest: PointOfInterest) => {
				this.pointOfInterest.fromObject(pointOfInterest.toObject());

				// Refresh history
				const params = {
					entity_id: this.id,
					entity_type: 'point_of_interest',
				};
				if (!this.historySearchParams) {
					this.historySearchParams = new ActivityLogSearchParams(
						params
					);
				} else {
					this.historySearchParams.fromObject(params);
				}
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the point of interest is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the point of interest is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/point-of-interest')
			.catch((error) => this.errorService.handle(error));
	}
}
