import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { TextTranslated, TextTranslatedInterface } from '../text-translated';

export interface ModuleQCMResponseInterface extends BaseModelInterface {
	created_at: number | Date;
	label: (string | TextTranslated | TextTranslatedInterface)[];
	is_correct: boolean;
	explain: (string | TextTranslated | TextTranslatedInterface)[];
}
export interface ModuleQCMResponsePayload {
	label: (string | TextTranslated | TextTranslatedInterface)[];
	is_correct: boolean;
	explain: (string | TextTranslated | TextTranslatedInterface)[];
}
type ModuleQCMResponsePayloadKey = keyof ModuleQCMResponsePayload;
export class ModuleQCMResponse extends BaseModel<
	ModuleQCMResponseInterface,
	ModuleQCMResponsePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: ModuleQCMResponseInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);

		if (this.props.label instanceof Array) {
			this.props.label = (<TextTranslatedInterface[]>(
				this.props.label
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}

		if (this.props.explain instanceof Array) {
			this.props.explain = (<TextTranslatedInterface[]>(
				this.props.explain
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}

		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ModuleQCMResponseInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);

		if (this.props.label instanceof Array) {
			props.label = (<TextTranslated[]>props.label).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}

		if (this.props.explain instanceof Array) {
			props.explain = (<TextTranslated[]>props.explain).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}

		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ModuleQCMResponsePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ModuleQCMResponseInterface);

		payload.label = payload.label
			? payload.label.map((i) => this.extractId(i))
			: [];

		payload.explain = payload.explain
			? payload.explain.map((i) => this.extractId(i))
			: [];

		return payload as ModuleQCMResponsePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ModuleQCMResponsePayloadKey[] {
		return ['label', 'is_correct', 'explain'];
	}
}
