<ng-container *ngIf="!plateau?.isNew()">
	<ng-container *ngIf="buildInfos$ | async as buildInfos">
		<div>
			<!-- Actions -->
			<div>
				<button
					class="mr-4"
					nz-button
					[nzLoading]="buildInfos.status === 'in-progress'"
					(click)="publishModal = true"
					type="button"
				>
					<ng-container [ngSwitch]="buildInfos.status">
						<span *ngSwitchCase="'in-progress'">{{
							'plateau_createBundle_inProgress' | translate
						}}</span>
						<span *ngSwitchDefault>{{
							'plateau_createBundle' | translate
						}}</span>
					</ng-container>
				</button>

				<button
					class="mr-4"
					nz-button
					[disabled]="buildInfos.status !== 'private'"
					(click)="moveToPublicModal = true"
					type="button"
				>
					{{ 'plateau_moveToPublic' | translate }}
				</button>
			</div>
			<!-- /Actions -->

			<!-- Infos last build -->
			<div>
				<span
					>{{ 'plateau_last-build-status' | translate }}:
					<b>{{
						'plateau_build-status_' + buildInfos.status | translate
					}}</b>
					<ng-container *ngIf="buildInfos.status !== undefined">
						(<a
							(click)="
								detailsModal = true; $event.stopPropagation()
							"
							class="text-primary"
							>{{ 'plateau_last-build-details' | translate }}</a
						>)</ng-container
					></span
				>
			</div>
			<div></div>
			<!-- /Infos last build -->
		</div>

		<!-- Modal-->
		<nz-modal
			[(nzVisible)]="publishModal"
			(nzOnCancel)="publishModal = false"
			(nzOnOk)="createAssetBundle()"
			[nzTitle]="'plateau_createBundle' | translate"
			[nzContent]="'plateau_publish_content' | translate"
			[nzOkLoading]="modalProgress"
			[nzCancelLoading]="modalProgress"
		>
		</nz-modal>
		<nz-modal
			[(nzVisible)]="moveToPublicModal"
			(nzOnCancel)="moveToPublicModal = false"
			(nzOnOk)="moveToPublic()"
			[nzTitle]="'plateau_moveToPublic' | translate"
			[nzContent]="'plateau_moveToPublic_content' | translate"
			[nzOkLoading]="modalProgress"
			[nzCancelLoading]="modalProgress"
		>
		</nz-modal>

		<nz-modal
			[(nzVisible)]="detailsModal"
			(nzOnOk)="detailsModal = false"
			(nzOnCancel)="detailsModal = false"
			[nzTitle]="'plateau_last-build-details_title' | translate"
		>
			<pre>{{ buildInfos | json }}</pre>
		</nz-modal>
		<!-- /Modal-->
	</ng-container>
</ng-container>
