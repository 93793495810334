<nz-card>
	<div class="row" [formGroup]="form">
		<div class="col-12 col-md-6">
			<!-- Label -->
			<nz-form-item>
				<h4>
					{{ 'module-qcm-response_label' | translate }}
				</h4>
				<app-language-tab
					[name]="'label'"
					[model]="moduleQCMResponse"
				></app-language-tab>
			</nz-form-item>
			<!-- /Label -->
		</div>
		<div class="col-12 col-md-6">
			<!-- Is Correct -->
			<nz-form-item>
				<h4>
					{{ 'module-qcm-response_is-correct' | translate }}
				</h4>
				<nz-form-control>
					<nz-select
						class="w-100"
						[nzPlaceHolder]="'common_value-boolean' | translate"
						formControlName="is_correct"
					>
						<nz-option
							[nzValue]="true"
							[nzLabel]="'common_true' | translate"
						></nz-option>
						<nz-option
							[nzValue]="false"
							[nzLabel]="'common_false' | translate"
						></nz-option>
					</nz-select>
				</nz-form-control>
			</nz-form-item>
			<!-- /Is Correct -->
		</div>
		<div class="col-12">
			<!-- Explain -->
			<nz-form-item>
				<h4>
					{{ 'module-qcm-response_explain' | translate }}
				</h4>
				<app-language-tab
					[name]="'explain'"
					[model]="moduleQCMResponse"
				></app-language-tab>
			</nz-form-item>
			<!-- /Explain -->
		</div>
	</div>

	<nz-divider *ngIf="!moduleQCMResponse.isNew()"></nz-divider>
	<div *ngIf="!moduleQCMResponse.isNew()" class="row">
		<div class="col-12 col-md-6">
			<!-- _Id -->
			<div class="mb-4">
				<h4>{{ 'module-qcm-response_-id' | translate }}</h4>
				<div>{{ moduleQCMResponse.props._id }}</div>
			</div>
			<!-- /_Id -->
		</div>
		<div class="col-12 col-md-6">
			<!-- Created At -->
			<div class="mb-4">
				<h4>
					{{ 'module-qcm-response_created-at' | translate }}
				</h4>
				<div>
					{{ moduleQCMResponse.props.created_at | date: 'medium' }}
				</div>
			</div>
			<!-- /Created At -->
		</div>
	</div>
</nz-card>
