import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { ModuleQCMResponse } from '../module-qcm-response';
import { ModuleQCMResponseService } from '../module-qcm-response.service';
import { LanguageTabComponent } from '@app/models/language';

@Component({
	selector: 'hpf-module-qcm-response-form',
	templateUrl: './module-qcm-response-form.component.html',
})
export class ModuleQCMResponseFormComponent implements OnInit, OnDestroy {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;

	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;

	/** The module qcm response to inject in the form */
	@Input() moduleQCMResponse: ModuleQCMResponse;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<ModuleQCMResponse>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;

	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	constructor(
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private moduleQCMResponseService: ModuleQCMResponseService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'module_qcm_response',
			'update'
		));

		// Init model
		if (!this.moduleQCMResponse)
			this.moduleQCMResponse = new ModuleQCMResponse();
		// Create form
		if (!this.form)
			this.form = ModuleQCMResponseFormComponent.getForm(
				this.moduleQCMResponse
			);

		// Update form when model loads or changes
		this.modelSubscription = this.moduleQCMResponse.subscribe(() => {
			this.updateForm();
		});
	}

	static getForm(moduleQCMResponse?: ModuleQCMResponse): FormGroup {
		return new FormGroup({
			is_correct: new FormControl(moduleQCMResponse.props.is_correct, [
				Validators.required,
			]),
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			for (let element of this.languageTabs) {
				await element.submit();
			}

			// Creation or update ?
			if (this.moduleQCMResponse.isNew()) {
				// Creation
				const moduleQCMResponse: ModuleQCMResponse = await this.moduleQCMResponseService.create(
					this.moduleQCMResponse.toPayload()
				);
				this.moduleQCMResponse.props._id = moduleQCMResponse.props._id;
				this.create.next(moduleQCMResponse);
			} else {
				// Update
				await this.moduleQCMResponseService.update(
					this.moduleQCMResponse.getId(),
					this.moduleQCMResponse.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.moduleQCMResponse.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			is_correct: this.moduleQCMResponse.props.is_correct,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.moduleQCMResponseService
			.remove(this.moduleQCMResponse.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
