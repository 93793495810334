import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { ModuleEnSavoirPlus } from '../../module-en-savoir-plus';

@Component({
	selector: 'hpf-module-en-savoir-plus-create',
	templateUrl: './module-en-savoir-plus-create.component.html',
})
export class ModuleEnSavoirPlusCreateComponent implements OnInit {
	/** The module en savoir plus */
	moduleEnSavoirPlus = new ModuleEnSavoirPlus();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the module en savoir plus is created */
	onCreate(moduleEnSavoirPlus: ModuleEnSavoirPlus): void {
		this.router
			.navigateByUrl(
				`/module-en-savoir-plus/${moduleEnSavoirPlus.getId()}`
			)
			.catch((error) => this.errorService.handle(error));
	}
}
