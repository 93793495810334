<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
>
</nz-modal>

<form
	nz-form
	class="plateau-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="plateau.isNew()">{{
									'plateau_create-form-title' | translate
								}}</span>
								<span *ngIf="!plateau.isNew()">{{
									plateau.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!plateau.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate: { id: plateau.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div class="d-flex">
							<hpf-plateau-build-action
								*ngIf="!plateau.isNew()"
								[plateau]="plateau"
								(statusChange)="buildStatus = $event"
							></hpf-plateau-build-action>

							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								[disabled]="buildStatus === 'in-progress'"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'plateau',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'plateau',
									action: 'delete'
								}"
							>
								<button
									*ngIf="!plateau.isNew() && deletable"
									class="ml-4"
									nz-button
									nzShape="circle"
									[disabled]="buildStatus === 'in-progress'"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12 col-md-6">
						<!-- Name -->

						<nz-form-item>
							<h4>{{ 'plateau_name' | translate }}</h4>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'common_value-string' | translate
									"
									formControlName="name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Name -->
					</div>

					<div class="col-12 col-md-6"></div>

					<div class="col-12 col-md-6">
						<!-- Title -->

						<h4>{{ 'plateau_title' | translate }}</h4>
						<app-language-tab
							[name]="'title'"
							[model]="plateau"
						></app-language-tab>
					</div>
					<div class="col-12 col-md-6">
						<!-- Logo -->

						<nz-form-item>
							<h4>{{ 'plateau_logo' | translate }}</h4>
							<app-image-modal-button
								formControlName="logo"
							></app-image-modal-button>
						</nz-form-item>
						<!-- /Logo -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Languages -->

						<nz-form-item>
							<h4>{{ 'plateau_languages' | translate }}</h4>
							<nz-form-control
								[nzValidateStatus]="form.get('languages')"
							>
								<hpf-language-select
									[controlName]="'languages'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="plateau.props.languages"
									[multiple]="true"
									[filterEnabled]="true"
									[placeholder]="
										'language_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-language-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Languages -->
					</div>

					<div class="jumbotron col-12">
						<h3>{{ 'plateau_limites' | translate }}</h3>
						<hr />
						<div class="row">
							<div class="col-12 col-md-6">
								<!-- Min Lat -->

								<nz-form-item>
									<h4>{{ 'plateau_min-lat' | translate }}</h4>
									<nz-form-control>
										<nz-input-number
											class="w-100"
											formControlName="min_lat"
											[nzMin]="-90"
											[nzMax]="90"
											[nzStep]="1"
											[nzPlaceHolder]="
												'common_value-number'
													| translate
											"
											(keyup)="locationFormUpdate.next()"
										>
										</nz-input-number>
									</nz-form-control>
								</nz-form-item>
								<!-- /Min Lat -->
							</div>
							<div class="col-12 col-md-6">
								<!-- Max Lat -->

								<nz-form-item>
									<h4>{{ 'plateau_max-lat' | translate }}</h4>
									<nz-form-control>
										<nz-input-number
											class="w-100"
											formControlName="max_lat"
											[nzMin]="-90"
											[nzMax]="90"
											[nzStep]="1"
											[nzPlaceHolder]="
												'common_value-number'
													| translate
											"
											(keyup)="locationFormUpdate.next()"
										>
										</nz-input-number>
									</nz-form-control>
								</nz-form-item>
								<!-- /Max Lat -->
							</div>
							<div class="col-12 col-md-6">
								<!-- Min Lng -->

								<nz-form-item>
									<h4>{{ 'plateau_min-lng' | translate }}</h4>
									<nz-form-control>
										<nz-input-number
											class="w-100"
											formControlName="min_lng"
											[nzMin]="-180"
											[nzMax]="180"
											[nzStep]="1"
											[nzPlaceHolder]="
												'common_value-number'
													| translate
											"
											(keyup)="locationFormUpdate.next()"
										>
										</nz-input-number>
									</nz-form-control>
								</nz-form-item>
								<!-- /Min Lng -->
							</div>
							<div class="col-12 col-md-6">
								<!-- Max Lng -->

								<nz-form-item>
									<h4>{{ 'plateau_max-lng' | translate }}</h4>
									<nz-form-control>
										<nz-input-number
											class="w-100"
											formControlName="max_lng"
											[nzMin]="-180"
											[nzMax]="180"
											[nzStep]="1"
											[nzPlaceHolder]="
												'common_value-number'
													| translate
											"
											(keyup)="locationFormUpdate.next()"
										>
										</nz-input-number>
									</nz-form-control>
								</nz-form-item>
								<!-- /Max Lng -->
							</div>
						</div>
						<div class="col-12">
							<!-- Map -->
							<div class="mb-4">
								<h4>
									{{ 'point-of-interest_map' | translate }}
								</h4>
								<div
									*ngIf="mapDisplay"
									style="height: 500px;"
									leaflet
									leafletDraw
									[leafletDrawOptions]="mapDrawOptions"
									[(leafletCenter)]="mapOptions.center"
									[(leafletLayers)]="mapOptions.layers"
									[(leafletZoom)]="mapOptions.zoom"
									[leafletMaxBounds]="mapOptions.maxBounds"
								>
									<div [leafletLayer]="mapDrawnItems"></div>
									<div [leafletLayer]="mapMarkerItems"></div>
								</div>
							</div>
							<!-- Map -->
						</div>
					</div>

					<div class="jumbotron col-12">
						<h3>{{ 'plateau_present' | translate }}</h3>
						<hr />
						<div class="row">
							<div class="col-12 col-md-6">
								<!-- Image Intro -->

								<nz-form-item>
									<h4>
										{{ 'plateau_image-intro' | translate }}
									</h4>

									<app-image-modal-button
										formControlName="image_intro"
									></app-image-modal-button>
								</nz-form-item>
								<!-- /Image Intro -->
							</div>
							<div class="col-12 col-md-6">
								<!-- Panels -->

								<h4>{{ 'plateau_panels' | translate }}</h4>
								<app-language-tab
									[name]="'panels'"
									[model]="plateau"
								></app-language-tab>
							</div>
						</div>
					</div>
					<div class="col-12">
						<h3>{{ 'common_accessibilite' | translate }}</h3>
						<hr />
					</div>

					<div class="col-12 col-md-6">
						<!-- Audio Descriptions -->

						<nz-form-item>
							<h4>
								{{ 'plateau_audio-descriptions' | translate }}
							</h4>
							<nz-form-control
								[nzValidateStatus]="
									form.get('audio_descriptions')
								"
							>
								<hpf-audio-description-select
									[controlName]="'audio_descriptions'"
									[formGroup]="form"
									[quickFormEnabled]="quickFormEnabled"
									[(model)]="plateau.props.audio_descriptions"
									[multiple]="true"
									[nullable]="true"
									[filterEnabled]="true"
									[placeholder]="
										'audio-description_common_search-placeholder'
											| translate
									"
									[emptyLabel]="'common_empty' | translate"
								>
								</hpf-audio-description-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Audio Descriptions -->
					</div>
				</div>

				<nz-divider *ngIf="!plateau.isNew() && !readOnly"></nz-divider>
				<div *ngIf="!plateau.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>{{ 'plateau_-id' | translate }}</h4>
							<div>{{ plateau.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>{{ 'plateau_created-at' | translate }}</h4>
							<div>
								{{ plateau.props.created_at | date: 'medium' }}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Name -->
						<div class="mb-4">
							<h4>{{ 'plateau_name' | translate }}</h4>
							<div>{{ plateau.props.name }}</div>
						</div>
						<!-- /Name -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Title -->
						<div class="mb-4">
							<h4>{{ 'plateau_title' | translate }}</h4>
							<div *ngIf="plateau.titleExists()">
								<div *ngFor="let item of plateau.props.title">
									<a
										class="mr-2"
										[routerLink]="[
											'/text-translated',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Title -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Languages -->
						<div class="mb-4">
							<h4>{{ 'plateau_languages' | translate }}</h4>
							<div *ngIf="plateau.languagesExists()">
								<div
									*ngFor="let item of plateau.props.languages"
								>
									<a
										class="mr-2"
										[routerLink]="[
											'/language',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Languages -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Min Lat -->
						<div class="mb-4">
							<h4>{{ 'plateau_min-lat' | translate }}</h4>
							<div>{{ plateau.props.min_lat }}</div>
						</div>
						<!-- /Min Lat -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Max Lat -->
						<div class="mb-4">
							<h4>{{ 'plateau_max-lat' | translate }}</h4>
							<div>{{ plateau.props.max_lat }}</div>
						</div>
						<!-- /Max Lat -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Min Lng -->
						<div class="mb-4">
							<h4>{{ 'plateau_min-lng' | translate }}</h4>
							<div>{{ plateau.props.min_lng }}</div>
						</div>
						<!-- /Min Lng -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Max Lng -->
						<div class="mb-4">
							<h4>{{ 'plateau_max-lng' | translate }}</h4>
							<div>{{ plateau.props.max_lng }}</div>
						</div>
						<!-- /Max Lng -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Logo -->
						<div class="mb-4">
							<h4>{{ 'plateau_logo' | translate }}</h4>
							<div *ngIf="plateau.logoExists()">
								<a
									[routerLink]="[
										'/image',
										plateau.props.logo.getId()
									]"
								>
									{{ plateau.props.logo.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Logo -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Image Intro -->
						<div class="mb-4">
							<h4>{{ 'plateau_image-intro' | translate }}</h4>
							<div *ngIf="plateau.imageIntroExists()">
								<a
									[routerLink]="[
										'/image',
										plateau.props.image_intro.getId()
									]"
								>
									{{ plateau.props.image_intro.getLabel() }}
								</a>
							</div>
						</div>
						<!-- /Image Intro -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Panels -->
						<div class="mb-4">
							<h4>{{ 'plateau_panels' | translate }}</h4>
							<div *ngIf="plateau.panelsExists()">
								<div *ngFor="let item of plateau.props.panels">
									<a
										class="mr-2"
										[routerLink]="[
											'/text-translated',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Panels -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Audio Descriptions -->
						<div class="mb-4">
							<h4>
								{{ 'plateau_audio-descriptions' | translate }}
							</h4>
							<div *ngIf="plateau.audioDescriptionsExists()">
								<div
									*ngFor="
										let item of plateau.props
											.audio_descriptions
									"
								>
									<a
										class="mr-2"
										[routerLink]="[
											'/audio-description',
											item.getId()
										]"
									>
										{{ item.getLabel() }}
									</a>
								</div>
							</div>
						</div>
						<!-- /Audio Descriptions -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
