import { Component } from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import { ErrorService } from '@app/services';
import { ModuleQCMResponseSearchParams } from '../module-qcm-response-search-params';
import { ModuleQCMResponseService } from '../module-qcm-response.service';
import { ModuleQCMResponse } from '../module-qcm-response';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
	selector: 'hpf-module-qcm-response-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class ModuleQCMResponseSelectComponent extends EntitySelectComponent<
	ModuleQCMResponse
> {
	protected modelName = 'module_qcm_response';
	/**
	 * Constructor
	 * @param {ModuleQCMResponseService} moduleQCMResponseService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private moduleQCMResponseService: ModuleQCMResponseService,
		protected errorService: ErrorService,
		protected quickFormService: QuickFormService
	) {
		super(errorService, quickFormService);
	}
	ngOnInit() {
		super.ngOnInit();
		// Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if (!(model instanceof ModuleQCMResponse)) return;
					if (this.multiple) {
						if (!this.model) this.model = [];
						(<ModuleQCMResponse[]>this.model).push(
							<ModuleQCMResponse>model
						);
					} else {
						this.model = <ModuleQCMResponse>model;
					}
					this.refresh();
				}
			)
		);
	}
	/** @inheritDoc */
	protected async getList(): Promise<ModuleQCMResponse[]> {
		const params = new ModuleQCMResponseSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		return (await this.moduleQCMResponseService.list(params.toObject()))
			.items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<ModuleQCMResponse> {
		return await this.moduleQCMResponseService.get(id);
	}
}
