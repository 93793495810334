import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ModuleEnSavoirPlusSearchParams } from '../../module-en-savoir-plus-search-params';
import { ModuleEnSavoirPlus } from '../../module-en-savoir-plus';
import { Helpers } from '@app/shared';
@Component({
	selector: 'hpf-module-en-savoir-plus-list',
	templateUrl: './module-en-savoir-plus-list.component.html',
	styleUrls: [],
})
export class ModuleEnSavoirPlusListComponent implements OnInit, OnDestroy {
	/** The filters */
	searchParams = new ModuleEnSavoirPlusSearchParams(
		this.route.snapshot.queryParams
	);
	/** The search params subscription */
	private paramsSubscription: RxJsSubscription;
	/** Used to open or close filter drawer */
	filtersDrawer = false;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private location: Location
	) {}
	/** Init */
	ngOnInit() {
		// Update query string when params change
		this.paramsSubscription = this.searchParams.subscribe(() => {
			this.location.replaceState(
				'/module-en-savoir-plus',
				Helpers.toQueryString(this.searchParams.toObject())
			);
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.paramsSubscription.unsubscribe();
	}
	/** Called when the user select a row in the table */
	onSelect(item: ModuleEnSavoirPlus) {
		this.router.navigateByUrl(`/module-en-savoir-plus/${item.getId()}`);
	}
	/** Called when the user click on create */
	onCreate() {
		this.router.navigate(['/module-en-savoir-plus/create']);
	}
}
