import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { ModuleQCM } from '../module-qcm';
import { ModuleQCMService } from '../module-qcm.service';
import { ModuleQCMResponseInlineFormComponent } from '@app/models/module-qcm-response/module-qcm-response-inline-form/module-qcm-response-inline-form.component';
import { ModuleQCMResponse } from '@app/models/module-qcm-response';
import { LanguageTabComponent } from '@app/models/language';

@Component({
	selector: 'hpf-module-qcm-form',
	templateUrl: './module-qcm-form.component.html',
})
export class ModuleQCMFormComponent implements OnInit {
	@ViewChildren('responsesFormElm') responsesFormList: QueryList<
		ModuleQCMResponseInlineFormComponent
	>;

	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;

	/** The module qcm to inject in the form */
	@Input() moduleQCM: ModuleQCM;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<ModuleQCM>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;

	/** Constructor */
	constructor(
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private moduleQCMService: ModuleQCMService,
		private sessionService: SessionService
	) {}

	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'module_qcm',
			'update'
		));

		// Init model
		if (!this.moduleQCM) this.moduleQCM = new ModuleQCM();

		// Create form
		this.form = ModuleQCMFormComponent.getForm(this.moduleQCM);
	}

	static getForm(moduleQCM: ModuleQCM): FormGroup {
		const responses = new FormArray(
			(moduleQCM.props.responses || []).map((response) =>
				ModuleQCMResponseInlineFormComponent.getForm(
					response as ModuleQCMResponse
				)
			)
		);

		return new FormGroup({
			image: new FormControl(moduleQCM.props.image, [
				Validators.required,
			]),
			responses,
		});
	}

	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();

			for (let element of this.languageTabs) {
				await element.submit();
			}

			await Promise.all(
				this.responsesFormList.map((responseForm) => {
					return responseForm.onSubmit();
				})
			);

			// Creation or update ?
			if (this.moduleQCM.isNew()) {
				// Creation
				const moduleQCM: ModuleQCM = await this.moduleQCMService.create(
					this.moduleQCM.toPayload()
				);
				this.create.next(moduleQCM);
			} else {
				// Update
				await this.moduleQCMService.update(
					this.moduleQCM.getId(),
					this.moduleQCM.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		const externalKeys = ['responses'];
		for (const key of Object.keys(this.form.controls)) {
			if (!externalKeys.includes(key)) {
				this.moduleQCM.props[key] = this.form.get(key).value;
			}
		}

		this.responsesFormList.map((form) => form.updateModel());
	}

	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.moduleQCMService
			.remove(this.moduleQCM.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}

	addDependency(field: string, model?: ModuleQCMResponse): void {
		let form: FormGroup;
		switch (field) {
			case 'responses':
				const response: ModuleQCMResponse = !model
					? new ModuleQCMResponse()
					: <ModuleQCMResponse>model;

				if (!this.moduleQCM.props.responses)
					this.moduleQCM.props.responses = [];
				form = ModuleQCMResponseInlineFormComponent.getForm(response);
				this.moduleQCM.props.responses.push(response);

				break;
		}

		(<FormArray>this.form.get(field)).push(form);
	}

	removeDependency(field: string, index: number): void {
		this.moduleQCM.props[field].splice(index, 1);
		(<FormArray>this.form.get(field)).removeAt(index);
	}
}
