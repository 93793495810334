import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription as RxJsSubscription } from 'rxjs';
import { ErrorService } from '@app/services';
import { ModuleQCM } from '../../module-qcm';
import { ModuleQCMService } from '../../module-qcm.service';

@Component({
	selector: 'hpf-module-qcm-edit',
	templateUrl: './module-qcm-edit.component.html',
})
export class ModuleQCMEditComponent implements OnInit, OnDestroy {
	/** The module qcm */
	moduleQCM;
	/** The module qcm */
	private id: string;
	/** The id subscription */
	private idSubscription: RxJsSubscription;
	/** Constructor */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private errorService: ErrorService,
		private moduleQCMService: ModuleQCMService
	) {}
	/** Init */
	ngOnInit() {
		// Get the module qcm after reading the id
		this.idSubscription = this.route.params.subscribe((params) => {
			this.id = params['id'];
			this.refresh();
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.idSubscription.unsubscribe();
	}
	/** Fetch module qcm from API */
	private refresh() {
		this.moduleQCMService
			.get(this.id, {
				_populate: [
					'title.lang',
					'question',
					'image',
					'responses.label',
					'responses.explain',
				],
			})
			.then((moduleQCM: ModuleQCM) => {
				this.moduleQCM = moduleQCM;
			})
			.catch((error) => this.errorService.handle(error));
	}
	/** Called when the module qcm is updated */
	onUpdate(): void {
		this.refresh();
	}
	/** Called when the module qcm is deleted */
	onDelete(): void {
		this.router
			.navigateByUrl('/module-qcm')
			.catch((error) => this.errorService.handle(error));
	}
}
