import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'hpf-module-qcm',
	templateUrl: './module-qcm.component.html',
})
export class ModuleQCMComponent implements OnInit {
	/** Constructor */
	constructor() {}

	/** Init */
	ngOnInit() {}
}
