<nz-modal
	[(nzVisible)]="deleteModal"
	(nzOnCancel)="deleteModal = false"
	(nzOnOk)="onDelete()"
	[nzTitle]="'common_deletion' | translate"
	[nzContent]="'common_delete-one' | translate"
	[nzOkLoading]="deleting"
></nz-modal>
<form
	nz-form
	class="module-qcm-response-form w-100"
	*ngIf="form"
	[formGroup]="form"
	(ngSubmit)="onSubmit()"
	[nzAutoTips]="explainErrorsService.autoTips"
>
	<nz-affix>
		<div class="py-4 border-bottom bg-white">
			<div class="container">
				<div class="row">
					<div
						class="col-12 d-flex align-items-center justify-content-between"
					>
						<!-- Title -->
						<div>
							<h1 class="mb-0">
								<span *ngIf="moduleQCMResponse.isNew()">{{
									'module-qcm-response_create-form-title'
										| translate
								}}</span>
								<span *ngIf="!moduleQCMResponse.isNew()">{{
									moduleQCMResponse.getLabel()
								}}</span>
							</h1>
							<small
								*ngIf="!moduleQCMResponse.isNew()"
								class="text-black-50"
								>{{
									'common_id'
										| translate
											: { id: moduleQCMResponse.getId() }
								}}</small
							>
						</div>
						<!-- /Title -->

						<!-- Actions -->
						<div>
							<button
								nz-button
								[disabled]="form.invalid"
								[nzLoading]="saving"
								nzType="primary"
								*appRestrictedByScope="{
									model: 'module_qcm_response',
									action: 'update'
								}"
							>
								{{ 'common_save' | translate }}
							</button>
							<ng-container
								*appRestrictedByScope="{
									model: 'module_qcm_response',
									action: 'delete'
								}"
							>
								<button
									*ngIf="
										!moduleQCMResponse.isNew() && deletable
									"
									class="ml-4"
									nz-button
									nzShape="circle"
									[title]="'common_delete' | translate"
									[nzLoading]="deleting"
									(click)="deleteModal = true"
									type="button"
								>
									<i nz-icon nzType="delete"></i>
								</button>
							</ng-container>
						</div>
						<!-- /Actions -->
					</div>
				</div>
			</div>
		</div>
	</nz-affix>
	<div class="container">
		<nz-card class="round-border my-5">
			<div *ngIf="loading" class="row">
				<div class="col-12">
					<nz-skeleton
						class="no-header"
						[nzActive]="true"
					></nz-skeleton>
				</div>
			</div>
			<div *ngIf="!loading">
				<div class="row" *ngIf="!readOnly">
					<div class="col-12 col-md-6">
						<!-- Label -->
						<nz-form-item>
							<h4>
								{{ 'module-qcm-response_label' | translate }}
							</h4>
							<app-language-tab
								[name]="'label'"
								[model]="moduleQCMResponse"
							></app-language-tab>
						</nz-form-item>
						<!-- /Label -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Is Correct -->
						<nz-form-item>
							<h4>
								{{
									'module-qcm-response_is-correct' | translate
								}}
							</h4>
							<nz-form-control>
								<nz-select
									class="w-100"
									[nzPlaceHolder]="
										'common_value-boolean' | translate
									"
									formControlName="is_correct"
								>
									<nz-option
										[nzValue]="true"
										[nzLabel]="'common_true' | translate"
									></nz-option>
									<nz-option
										[nzValue]="false"
										[nzLabel]="'common_false' | translate"
									></nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /Is Correct -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Explain -->
						<nz-form-item>
							<h4>
								{{ 'module-qcm-response_explain' | translate }}
							</h4>
							<app-language-tab
								[name]="'explain'"
								[model]="moduleQCMResponse"
							></app-language-tab>
						</nz-form-item>
						<!-- /Explain -->
					</div>
				</div>

				<nz-divider
					*ngIf="!moduleQCMResponse.isNew() && !readOnly"
				></nz-divider>
				<div *ngIf="!moduleQCMResponse.isNew()" class="row">
					<div class="col-12 col-md-6">
						<!-- _Id -->
						<div class="mb-4">
							<h4>{{ 'module-qcm-response_-id' | translate }}</h4>
							<div>{{ moduleQCMResponse.props._id }}</div>
						</div>
						<!-- /_Id -->
					</div>
					<div class="col-12 col-md-6">
						<!-- Created At -->
						<div class="mb-4">
							<h4>
								{{
									'module-qcm-response_created-at' | translate
								}}
							</h4>
							<div>
								{{
									moduleQCMResponse.props.created_at
										| date: 'medium'
								}}
							</div>
						</div>
						<!-- /Created At -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Label -->
						<div class="mb-4">
							<h4>
								{{ 'module-qcm-response_label' | translate }}
							</h4>
							<div>{{ moduleQCMResponse.props.label }}</div>
						</div>
						<!-- /Label -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Is Correct -->
						<div class="mb-4">
							<h4>
								{{
									'module-qcm-response_is-correct' | translate
								}}
							</h4>
							<div>
								<span
									*ngIf="
										moduleQCMResponse.props.is_correct ===
											true ||
										moduleQCMResponse.props.is_correct ===
											false
									"
								>
									{{
										(moduleQCMResponse.props.is_correct
											? 'common_true'
											: 'common_false'
										) | translate
									}}
								</span>
							</div>
						</div>
						<!-- /Is Correct -->
					</div>
					<div class="col-12 col-md-6" *ngIf="readOnly">
						<!-- Explain -->
						<div class="mb-4">
							<h4>
								{{ 'module-qcm-response_explain' | translate }}
							</h4>
							<div
								[innerHTML]="
									moduleQCMResponse.props.explain | nl2br
								"
							></div>
						</div>
						<!-- /Explain -->
					</div>
				</div>
			</div>
		</nz-card>
	</div>
</form>
