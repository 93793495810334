import { Component, Input, OnInit } from '@angular/core';
import { ModuleQCMResponseSearchParams } from '../module-qcm-response-search-params';
@Component({
	selector: 'hpf-module-qcm-response-filters',
	templateUrl: './module-qcm-response-filters.component.html',
})
export class ModuleQCMResponseFiltersComponent implements OnInit {
	/** The filters */
	@Input()
	searchParams: ModuleQCMResponseSearchParams = new ModuleQCMResponseSearchParams();
	/** Denotes if the page number should be set to 0 when filters change */
	@Input() resetPage = false;
	/** Constructor */
	constructor() {}
	/** Init */
	ngOnInit() {}
	/** Called when an input is changed */
	onChange() {
		if (this.resetPage) {
			this.searchParams.props._page = 0;
		}
		this.searchParams.next();
	}
	/** Clear all filters */
	public resetSearchParams() {
		this.searchParams.fromObject({});
	}
}
