import { Component, Input, OnInit } from '@angular/core';
import { ErrorService } from '@app/services';
import { ModuleQCMSearchParams } from '../module-qcm-search-params';
import { ModuleQCMService } from '../module-qcm.service';
@Component({
	selector: 'hpf-module-qcm-count',
	templateUrl: './module-qcm-count.component.html',
})
export class ModuleQCMCountComponent implements OnInit {
	/** The filters */
	@Input() searchParams: ModuleQCMSearchParams = new ModuleQCMSearchParams();
	/** Count result */
	count: number;
	/** Load flag */
	loaded = false;
	/** Constructor */
	constructor(
		private moduleQCMService: ModuleQCMService,
		private errorService: ErrorService
	) {}
	/** Init */
	ngOnInit() {
		this.moduleQCMService
			.count(this.searchParams.toObject())
			.then((count) => {
				this.count = count;
				this.loaded = true;
			})
			.catch((error) => this.errorService.handle(error));
	}
}
