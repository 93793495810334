import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	AfterViewInit,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { TinyMceConfig } from '@app/shared';
import { ModuleEnSavoirPlus } from '../module-en-savoir-plus';
import { ModuleEnSavoirPlusService } from '../module-en-savoir-plus.service';
import { LanguageTabComponent } from '@app/models/language';
@Component({
	selector: 'hpf-module-en-savoir-plus-form',
	templateUrl: './module-en-savoir-plus-form.component.html',
})
export class ModuleEnSavoirPlusFormComponent
	implements OnInit, OnDestroy, AfterViewInit {
	/** The model subscription */
	private modelSubscription: RxJsSubscription;

	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;

	/** The module en savoir plus to inject in the form */
	@Input() moduleEnSavoirPlus: ModuleEnSavoirPlus;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<ModuleEnSavoirPlus>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;
	/** The form group to use */
	form: FormGroup;
	/** TinyMCE config */
	tinyMceConfig = TinyMceConfig;
	/** Used to show TinyMCE editors after view init */
	showTinyMceEditors = false;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private moduleEnSavoirPlusService: ModuleEnSavoirPlusService,
		private sessionService: SessionService
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'module_en_savoir_plus',
			'update'
		));

		// Init model
		if (!this.moduleEnSavoirPlus)
			this.moduleEnSavoirPlus = new ModuleEnSavoirPlus();
		// Create form
		this.form = this.formBuilder.group({
			label: new FormControl(this.moduleEnSavoirPlus.props.label, [
				Validators.required,
			]),
			url: new FormControl(this.moduleEnSavoirPlus.props.url, []),
			image: new FormControl(this.moduleEnSavoirPlus.props.image, [
				Validators.required,
			]),
			diaporama: new FormControl(
				this.moduleEnSavoirPlus.props.diaporama,
				[]
			),
		});
		// Update form when model loads or changes
		this.modelSubscription = this.moduleEnSavoirPlus.subscribe(() => {
			this.updateForm();
		});
	}
	/** After view init */
	ngAfterViewInit(): void {
		// Workaround to load TinyMCE when is DOM ready
		setTimeout(() => {
			this.showTinyMceEditors = true;
		});
	}
	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();

			for (let element of this.languageTabs) {
				await element.submit();
			}

			// Creation or update ?
			if (this.moduleEnSavoirPlus.isNew()) {
				// Creation
				const moduleEnSavoirPlus: ModuleEnSavoirPlus = await this.moduleEnSavoirPlusService.create(
					this.moduleEnSavoirPlus.toPayload()
				);
				this.create.next(moduleEnSavoirPlus);
			} else {
				// Update
				await this.moduleEnSavoirPlusService.update(
					this.moduleEnSavoirPlus.getId(),
					this.moduleEnSavoirPlus.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.moduleEnSavoirPlus.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			label: this.moduleEnSavoirPlus.props.label,
			url: this.moduleEnSavoirPlus.props.url,
			image: this.moduleEnSavoirPlus.props.image,
			diaporama: this.moduleEnSavoirPlus.props.diaporama,
		});
	}
	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.moduleEnSavoirPlusService
			.remove(this.moduleEnSavoirPlus.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
