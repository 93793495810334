import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services';
import { ModuleQCMResponse } from '../../module-qcm-response';

@Component({
	selector: 'hpf-module-qcm-response-create',
	templateUrl: './module-qcm-response-create.component.html',
})
export class ModuleQCMResponseCreateComponent implements OnInit {
	/** The module qcm response */
	moduleQCMResponse = new ModuleQCMResponse();

	/** Constructor */
	constructor(private router: Router, private errorService: ErrorService) {}
	/** Init */
	ngOnInit() {}
	/** Called when the module qcm response is created */
	onCreate(moduleQCMResponse: ModuleQCMResponse): void {
		this.router
			.navigateByUrl(`/module-qcm-response/${moduleQCMResponse.getId()}`)
			.catch((error) => this.errorService.handle(error));
	}
}
