<nz-drawer
	[nzClosable]="false"
	[(nzVisible)]="filtersDrawer"
	nzPlacement="right"
	(nzOnClose)="filtersDrawer = false"
	[nzTitle]="'common_filters-title' | translate"
	nzWidth="300"
>
	<hpf-module-qcm-response-filters
		[searchParams]="searchParams"
		[resetPage]="true"
	></hpf-module-qcm-response-filters>
</nz-drawer>
<hpf-module-qcm-response-list-top-bar
	[title]="'module-qcm-response_common_name' | translate"
	(filterEvent)="filtersDrawer = true"
	[searchParams]="searchParams"
	(createEvent)="onCreate()"
	[createButton]="'common_create' | translate"
></hpf-module-qcm-response-list-top-bar>
<div class="container-fluid px-5">
	<div class="row my-5">
		<div class="col-12">
			<hpf-module-qcm-response-table
				[searchParams]="searchParams"
				[enableMultiSelect]="true"
				[showActions]="true"
				(select)="onSelect($event)"
			></hpf-module-qcm-response-table>
		</div>
	</div>
</div>
