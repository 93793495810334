<nz-drawer
	[nzClosable]="false"
	[(nzVisible)]="filtersDrawer"
	nzPlacement="right"
	(nzOnClose)="filtersDrawer = false"
	[nzTitle]="'common_filters-title' | translate"
	nzWidth="300"
>
	<hpf-module-en-savoir-plus-filters
		[searchParams]="searchParams"
		[resetPage]="true"
	></hpf-module-en-savoir-plus-filters>
</nz-drawer>
<hpf-module-en-savoir-plus-list-top-bar
	[title]="'module-en-savoir-plus_common_name' | translate"
	(filterEvent)="filtersDrawer = true"
	[searchParams]="searchParams"
	(createEvent)="onCreate()"
	[createButton]="'common_create' | translate"
	[showQuickSearch]="true"
	[quickSearchTooltip]="
		'common_search-by' | translate: { list: 'title, label' }
	"
	[quickSearchPlaceholder]="'common_quick-search' | translate"
	[showAdvancedSearch]="true"
></hpf-module-en-savoir-plus-list-top-bar>
<div class="container-fluid px-5">
	<div class="row my-5">
		<div class="col-12">
			<hpf-module-en-savoir-plus-table
				[searchParams]="searchParams"
				[enableMultiSelect]="true"
				[showActions]="true"
				(select)="onSelect($event)"
			></hpf-module-en-savoir-plus-table>
		</div>
	</div>
</div>
