import { Component } from '@angular/core';
import { EntityListTopBarComponent } from '@app/abstracts';
import { ModuleQCMSearchParams } from '../module-qcm-search-params';

@Component({
	selector: 'hpf-module-qcm-list-top-bar',
	templateUrl:
		'../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html',
})
export class ModuleQCMListTopBarComponent extends EntityListTopBarComponent<
	ModuleQCMSearchParams
> {}
