<!-- Title -->
<nz-form-item>
	<nz-form-label>{{
		'module-en-savoir-plus_title' | translate
	}}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.title"
	/>
</nz-form-item>
<!-- /Title -->
<!-- Label -->
<nz-form-item>
	<nz-form-label>{{
		'module-en-savoir-plus_label' | translate
	}}</nz-form-label>
	<input
		nz-input
		class="w-100"
		(keyup)="onChangeDebounced()"
		[placeholder]="'common_value-string' | translate"
		[(ngModel)]="searchParams.props.label"
	/>
</nz-form-item>
<!-- /Label -->
<button nz-button nzType="default" (click)="resetSearchParams()">
	{{ 'common_reset-filters' | translate }}
</button>
