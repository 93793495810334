import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import { Diaporama, DiaporamaInterface } from '../diaporama/diaporama';
import { TextTranslated, TextTranslatedInterface } from '../text-translated';
import { Language } from '../language';
export interface ModuleEnSavoirPlusInterface extends BaseModelInterface {
	created_at: number | Date;
	title: (string | TextTranslated | TextTranslatedInterface)[];
	label: string;
	subtitle: (string | TextTranslated | TextTranslatedInterface)[];
	description: (string | TextTranslated | TextTranslatedInterface)[];
	url?: string;
	image: string | Image | ImageInterface;
	diaporama?: string | Diaporama | DiaporamaInterface;
}
export interface ModuleEnSavoirPlusPayload {
	title: (string | TextTranslated | TextTranslatedInterface)[];
	label: string;
	subtitle: (string | TextTranslated | TextTranslatedInterface)[];
	description: (string | TextTranslated | TextTranslatedInterface)[];
	url?: string;
	image: string | Image | ImageInterface;
	diaporama?: string | Diaporama | DiaporamaInterface;
}
type ModuleEnSavoirPlusPayloadKey = keyof ModuleEnSavoirPlusPayload;
export class ModuleEnSavoirPlus extends BaseModel<
	ModuleEnSavoirPlusInterface,
	ModuleEnSavoirPlusPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		const titleTranslated = this.getTitle();

		return titleTranslated !== '' ? titleTranslated : `${this.props.label}`;
	}

	getTitle(): string {
		const regex = /(<([^>]+)>)/gi;
		try {
			const lng = localStorage.getItem('lang');
			const tt = this.props.title.find(function (params: TextTranslated) {
				const l = params.props.lang as Language;
				return lng.toLowerCase() == l.props.name.toLowerCase();
			}) as TextTranslated;
			if (!tt) {
				return '';
			}
			return tt.props.text.replace(regex, '');
		} catch (error) {
			return '';
		}
	}
	/** Denotes if the instance of image has been populated */
	imageExists(): boolean {
		return (
			!!this.props &&
			this.props.image instanceof Image &&
			this.props.image.exists()
		);
	}
	/** Denotes if the instance of diaporama has been populated */
	diaporamaExists(): boolean {
		return (
			!!this.props &&
			this.props.diaporama instanceof Diaporama &&
			this.props.diaporama.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ModuleEnSavoirPlusInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.subtitle instanceof Array) {
			this.props.subtitle = (<TextTranslatedInterface[]>(
				this.props.subtitle
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.description instanceof Array) {
			this.props.description = (<TextTranslatedInterface[]>(
				this.props.description
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (typeof this.props.image === 'object') {
			this.props.image = new Image(<ImageInterface>this.props.image);
		}
		if (
			this.props.diaporama !== null &&
			typeof this.props.diaporama === 'object'
		) {
			this.props.diaporama = new Diaporama(
				<DiaporamaInterface>this.props.diaporama
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ModuleEnSavoirPlusInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.diaporama === 'undefined') {
			props.diaporama = null;
		}
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		if (typeof props.subtitle === 'undefined') {
			props.subtitle = [];
		}
		if (typeof props.description === 'undefined') {
			props.description = [];
		}
		if (typeof props.url !== 'string' || props.url.length === 0) {
			props.url = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.subtitle instanceof Array) {
			props.subtitle = (<TextTranslated[]>props.subtitle).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.description instanceof Array) {
			props.description = (<TextTranslated[]>props.description).map(
				(item) => {
					return item instanceof TextTranslated
						? item.toObject()
						: item;
				}
			);
		}
		if (props.image instanceof Image) {
			props.image = props.image.toObject();
		}
		if (props.diaporama !== null && props.diaporama instanceof Diaporama) {
			props.diaporama = props.diaporama.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ModuleEnSavoirPlusPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ModuleEnSavoirPlusInterface);
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		payload.subtitle = payload.subtitle
			? payload.subtitle.map((i) => this.extractId(i))
			: null;
		payload.description = payload.description
			? payload.description.map((i) => this.extractId(i))
			: null;
		payload.image = payload.image ? this.extractId(payload.image) : null;
		payload.diaporama = payload.diaporama
			? this.extractId(payload.diaporama)
			: null;
		return payload as ModuleEnSavoirPlusPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ModuleEnSavoirPlusPayloadKey[] {
		return [
			'title',
			'label',
			'subtitle',
			'description',
			'url',
			'image',
			'diaporama',
		];
	}
}
