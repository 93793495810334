import { Injectable } from '@angular/core';
import { ModuleQCM, ModuleQCMInterface, ModuleQCMPayload } from './module-qcm';
import { ModuleQCMSearchParamsInterface } from './module-qcm-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ModuleQCMService extends BaseModelService<
	ModuleQCM,
	ModuleQCMInterface,
	ModuleQCMPayload,
	ModuleQCMSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'module-qcm';
	}
	/** @inheritDoc */
	protected new(object: ModuleQCMInterface): ModuleQCM {
		return new ModuleQCM(object);
	}
}
